import React from "react";
import {  useParams } from "react-router-dom";
import { AccountBox, CalendarMonth, CreditCard, Password, Person } from "@mui/icons-material";
import { MainButton, QueueClock, ScreenLoader, TextInput } from "../../components";
import { CardExpirationFormatter, CardNumberFormatter, CpfFormatter, MercadoPagoApi, CheckoutOrder, View, HOME_ID, SIGNIN_ID, CARD_PAYMENT_ID, QUEUE_ID, ApiCheckoutError, EVENT_ID } from "../../shared";
import { ApiContext, NavigationContext, NotificationsContext, QueueContext } from "../../contexts";
import "./paymentview.scss";
import { DropDownInput } from "../../components/forms/inputs/dropdowninput";

interface InstallmentOption {
    installments: number,
    installmentAmount: number,
    totalAmount: number,
}

class CardPaymentView extends View {
    id = CARD_PAYMENT_ID;
    route = "/checkout/payment/:eventId/card";
    defaultRoute = false;
    authNeeded = true;
    header = {
        backClick: () => { this.navigation!.navigate(-1); },
        supportClick: () => {}
    };
    render = () => {
        const params = this.params = useParams();

        const [firstLoad, setFirstLoad] = React.useState<boolean>(true);
        const [expirationTime, setExpirationTime] = React.useState<number>();
        const [order, setOrder] = React.useState<CheckoutOrder>();

        const [name, setName] = React.useState<string>("");
        const nameRef = React.useRef<HTMLInputElement>(null);

        const [cpf, setCpf] = React.useState<string>("");
        const [cpfCursor, setCpfCursor] = React.useState<number>(0);
        const cpfRef = React.useRef<HTMLInputElement>(null);
        const cpfFormatter = new CpfFormatter();

        const [cardBrand, setCardBrand] = React.useState<string>("");
        const [cardNumber, setCardNumber] = React.useState<string>("");
        const [cardNumberCursor, setCardNumberCursor] = React.useState<number>(0);
        const cardNumberRef = React.useRef<HTMLInputElement>(null);
        const cardNumberFormatter = new CardNumberFormatter();

        const [cardExpiration, setCardExpiration] = React.useState<string>("");
        const [cardExpirationCursor, setCardExpirationCursor] = React.useState<number>(0);
        const cardExpirationRef = React.useRef<HTMLInputElement>(null);
        const cardExpirationFormatter = new CardExpirationFormatter();

        const [cvv, setCvv] = React.useState<string>("");
        const cvvRef = React.useRef<HTMLInputElement>(null);

        const [installmentOptions, setInstallmentOptions] = React.useState<Array<InstallmentOption>>([]);
        const [installments, setInstallments] = React.useState<number>(1);

        const { pushNotification } = React.useContext(NotificationsContext);
        const api = React.useContext(ApiContext);
        const { getParsedQueueToken, abandonQueue } = React.useContext(QueueContext);
        const { navigate, views, goTo } = this.navigation = React.useContext(NavigationContext);

        let timer: NodeJS.Timer | undefined;
        let waitPending = false;

        const getIds = () => {
            let eventId = params["eventId"];

            if(eventId === undefined) {
                navigate(-1);
            }

            return { eventId: eventId ?? "" };
        }

        const handleError = <T,>(response: T) => {
            const { eventId } = getIds();

            return (error: ApiCheckoutError) => {
                switch(error.errorType) {
                    case "QUEUE_WAITING":
                        goTo(views[QUEUE_ID], { eventId }, { ref: this.parsedRoute });
                        break;
                    case "NO_AUTH":
                    case "AUTH_EXPIRED":
                        goTo(views[SIGNIN_ID]);
                        break;
                    case "FETCH_GENERIC_ERROR":
                        // what to do?
                        break;
                }
                return response;
            }
        }

        const processOrderStatus = (order: CheckoutOrder | null) => {
            if(!!order && !order.invoiceCode) {
                switch(order.status) {
                    case "PaymentApproved":
                    case "Completed":
                        waitPending = false;
                        pushNotification("Pagamento aprovado! Seu passe está na sua carteira.");
                        abandonQueue(params["eventId"]!);
                        if(!!timer) {
                            clearInterval(timer);
                            timer = undefined;
                        }
                        goTo(views[HOME_ID]);
                        break;
                    case "PendingPayment":
                        if(!waitPending) {
                            waitPending = true;
                            pushNotification("Seu pagamento está processando, em alguns momentos iremos confirmá-lo.");
                        }
                        break;
                    case "PaymentRejected":
                        waitPending = false;
                        let rejectionMessage;
                        switch(order.rejectionDetails) {
                            case "CardRejectedBadExpirationDate":
                                rejectionMessage = "Data de validade do cartão está incorreta.";
                                break;
                            case "CardRejectedBadDetails":
                                rejectionMessage = "Informações do cartão estão incorretas.";
                                break;
                            case "CardRejectedBadCvv":
                                rejectionMessage = "Código de segurança do cartão está incorreto.";
                                break;
                            case "CardRejectedBlackList":
                                rejectionMessage = "Este cartão consta em uma lista de fraudes/golpes.";
                                break;
                            case "CardRejectedCallForAuthorize":
                                rejectionMessage = "Esta operação precisa de autorização do seu banco, contate-o para resolver o problema e tente novamente.";
                                break;
                            case "CardRejectedDisabled":
                                rejectionMessage = "Este cartão está desativado.";
                                break;
                            case "CardRejectedDuplicatedPayment":
                                rejectionMessage = "Operação duplicada.";
                                break;
                            case "CardRejectedHighRisk":
                                rejectionMessage = "Sistema anti-fraude rejeitou o pagamento.";
                                break;
                            case "CardRejectedInsufficientAmount":
                                rejectionMessage = "O cartão não possui limite para a operação.";
                                break;
                            case "CardRejectedInvalidInstallments":
                                rejectionMessage = "Número de parcelas inválido.";
                                break;
                            case "CardRejectedMaxAttempts":
                                rejectionMessage = "Número de tentativas máximo atingido.";
                                break;
                            default:
                                rejectionMessage = "Verifique com seu banco o motivo.";
                                break;
                        }
                        pushNotification(`Seu pagamento foi rejeitado. ${rejectionMessage} Qualquer dúvida entre em contato conosco pelo WhatsApp: (11) 95662-1753.`);
                        if(!!timer) {
                            clearInterval(timer);
                            timer = undefined;
                            setOrder(order);
                        }
                        break;
                }
            }
        }

        const loadCustomerData = async () => {
            const failableCustomerData = await api.customer.getCustomerData();
            return failableCustomerData.match({
                success: customerData => customerData,
                failure: handleError(null)
            });
        }

        const loadOrder = async (eventId: string) => {
            const failableCheckoutOrder = await api.checkout.getCheckoutOrder(eventId);
            return failableCheckoutOrder.match({
                success: order => order,
                failure: handleError(null)
            });
        }

        const loadOrderById = async (orderId: string) => {
            const failableCheckoutOrder = await api.checkout.getCheckoutOrderById(orderId);
            return failableCheckoutOrder.match({
                success: order => order,
                failure: handleError(null)
            });
        }

        const init = () => {
            const { eventId } = getIds();

            loadCustomerData().then(customerData => {
                if(!!customerData) {
                    setName(customerData.name || "");
                    setCpf(cpfFormatter.mask(customerData.taxId || "") ?? "");
                    setExpirationTime(getParsedQueueToken(eventId)?.exp);

                    loadOrder(eventId).then(order => {
                        if(!!order) {
                            setOrder(order);
                            /*timer = setInterval(() => {
                                loadOrderById(order.id).then(processOrderStatus);
                            }, 1500);*/
                        }
                    });
                }
                setFirstLoad(false);
            });

            return () => {
                if(timer != undefined) {
                    clearInterval(timer);
                }
            }
        }

        const resetInstallments = () => {
            setInstallments(1);
        }

        React.useEffect(init, []);
        React.useEffect(resetInstallments, [installmentOptions]);

        const changeNameHandle = (e: React.FormEvent<HTMLInputElement>) => {
            setName(e.currentTarget.value);
            e.preventDefault();
        }

        const changeCpfHandle = (e: React.FormEvent<HTMLInputElement>) => {
            const updatedValue = cpfFormatter.updateValue({text: cpf, cursor: cpfCursor}, {text: e.currentTarget.value, cursor: e.currentTarget.selectionEnd || 0})
            setCpf(updatedValue.text);
            setCpfCursor(updatedValue.cursor);
            e.preventDefault();
        }

        React.useEffect(() => {
            const input = cpfRef.current;
            if(input != null) {
                input.setSelectionRange(cpfCursor, cpfCursor);
            }
        }, [cpfRef, cpfCursor, cpf]);

        const changeCardNumberHandle = (e: React.FormEvent<HTMLInputElement>) => {
            const updatedValue = cardNumberFormatter.updateValue({text: cardNumber, cursor: cardNumberCursor}, {text: e.currentTarget.value, cursor: e.currentTarget.selectionEnd || 0})
            const unmaskedCardNumber = cardNumberFormatter.unmask(updatedValue.text);
            if(unmaskedCardNumber.length >= 6) {
                MercadoPagoApi.getPaymentMethods(unmaskedCardNumber).then((response) => {
                    if(response.status == 200) {
                        MercadoPagoApi.getInstallments(unmaskedCardNumber, order?.totalAmount ?? 0).then(installmentsResponse => {
                            if((installmentsResponse?.length ?? 0) > 0) {
                                setInstallmentOptions(installmentsResponse![0].payer_costs.map(option => ({ installments: option.installments, installmentAmount: (option as any).installment_amount, totalAmount: (option as any).total_amount })));
                            }
                        });
                        response.json().then(responseJSON => {
                            setCardBrand(responseJSON["results"] != null && responseJSON["results"].length > 0 ? responseJSON["results"][0]["id"] : "");
                        });
                    } else {
                        setCardBrand("");
                    }
                });
            } else {
                setCardBrand("");
            }
            setCardNumber(updatedValue.text);
            setCardNumberCursor(updatedValue.cursor);
            e.preventDefault();
        }

        React.useEffect(() => {
            const input = cardNumberRef.current;
            if(input != null) {
                input.setSelectionRange(cardNumberCursor, cardNumberCursor);
            }
        }, [cardNumberRef, cardNumberCursor, cardNumber]);

        const changeCardExpirationHandle = (e: React.FormEvent<HTMLInputElement>) => {
            const updatedValue = cardExpirationFormatter.updateValue({text: cardExpiration, cursor: cardExpirationCursor}, {text: e.currentTarget.value, cursor: e.currentTarget.selectionEnd || 0})
            setCardExpiration(updatedValue.text);
            setCardExpirationCursor(updatedValue.cursor);
            e.preventDefault();
        }

        React.useEffect(() => {
            const input = cardExpirationRef.current;
            if(input != null) {
                input.setSelectionRange(cardExpirationCursor, cardExpirationCursor);
            }
        }, [cardExpirationRef, cardExpirationCursor, cardExpiration]);

        const changeCvvHandle = (e: React.FormEvent<HTMLInputElement>) => {
            if(e.currentTarget.value.length <= 4 && (e.currentTarget.value === "" || !isNaN(parseInt(e.currentTarget.value)))) {
                setCvv(e.currentTarget.value);
            }
            e.preventDefault();
        }

        const changeInstallmentsHandle = (value?: string | number | readonly string[]) => {
            if(value !== undefined) {
                setInstallments(value as number);
            }
        }

        const prepareOrderAndPay = async (e: React.MouseEvent<HTMLButtonElement>) => {
            const eventId = params["eventId"]!;

            const exp = cardExpiration.split("/");
            const failableCardPayment = await api.checkout.chooseCardPaymentAndPay(eventId, {
                cardNumber: cardNumberFormatter.unmask(cardNumber),
                cardHolderName: name,
                cardHolderId: cpfFormatter.unmask(cpf),
                securityCode: cvv,
                expirationMonth: exp[0],
                expirationYear: `20${exp[1]}`
            }, cardBrand, installments);
            const success = failableCardPayment.match({
                success: order => {
                    processOrderStatus(order);
                    return true;
                },
                failure: handleError(false)
            });

            if(success) {
                if(!!timer) {
                    clearInterval(timer);
                }
                timer = setInterval(() => {
                    loadOrderById(order?.id ?? "").then(processOrderStatus);
                }, 1500);
            }

            e.preventDefault();
        }

        const expire = () => {
            const { eventId } = getIds();
            goTo(views[EVENT_ID], {eventId});
        }

        return firstLoad ? <ScreenLoader /> : <div id="payment">
            <QueueClock expirationTime={expirationTime} onExpire={expire} />
            <form id="payment-form">
                <div className="payment-form-field">
                    <div className="label">Titular do cartão</div>
                    <TextInput ref={nameRef} id="payment-name" name="name" value={name} prefix={<Person sx={{fontSize: 16.67, opacity: 0.6}} />} placeholder="Nome igual ao no cartão" onChange={changeNameHandle} />
                </div>
                <div className="payment-form-field">
                    <div className="label">CPF do titular</div>
                    <TextInput ref={cpfRef} id="payment-cpf" name="cpf" inputMode="numeric" value={cpf} prefix={<AccountBox sx={{fontSize: 16.67, opacity: 0.6}} />} placeholder="000.000.000-00" onChange={changeCpfHandle} />
                </div>
                <div className="payment-form-field">
                    <div className="label">Número do cartão</div>
                    <TextInput ref={cardNumberRef} id="payment-card-number" inputMode="numeric" name="cardNumber" value={cardNumber} prefix={<CreditCard sx={{fontSize: 16.67, opacity: 0.6}} />} suffix={cardBrand != "" ? <img src={`https://passify.co/${cardBrand}.svg`} width={30} /> : undefined} placeholder="0000 0000 0000 0000" onChange={changeCardNumberHandle} />
                </div>
                <div className="payment-form-row">
                    <div className="payment-form-field">
                        <div className="label">Data de validade</div>
                        <TextInput ref={cardExpirationRef} id="payment-card-expiration" inputMode="numeric" name="card-expiration" value={cardExpiration} prefix={<CalendarMonth sx={{fontSize: 16.67, opacity: 0.6}} />} placeholder="MM/AA" onChange={changeCardExpirationHandle} />
                    </div>
                    <div className="payment-form-field">
                        <div className="label">CVV</div>
                        <TextInput ref={cvvRef} id="payment-cvv" name="cvv" inputMode="numeric" password={true} value={cvv} prefix={<Password sx={{fontSize: 16.67, opacity: 0.6}} />} placeholder="000" onChange={changeCvvHandle} />
                    </div>
                </div>
                <div className="payment-form-field">
                    <div className="label">Número de parcelas</div>
                    <DropDownInput id="payment-installments" name="installments" selectedValue={installments} items={
                        installmentOptions.length > 0 ?
                            installmentOptions.map(option => ({
                                value: option.installments,
                                text: option.installments === 1 ?
                                    `À vista (${Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(option.totalAmount)})` :
                                    `${option.installments}x ${Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(option.installmentAmount)} (${Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(option.totalAmount)})`
                            })) :
                            [{
                                value: 1,
                                text: `À vista (${Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(order?.totalAmount ?? 0)})`
                            }]
                    } onChange={changeInstallmentsHandle} />
                </div>
                <div className="pay-button-container">
                    <MainButton content="Pagar" onClick={prepareOrderAndPay} />
                </div>
            </form>
        </div>;
    }
}

export { CardPaymentView };