import React from "react";
import { useSearchParams } from "react-router-dom";
import { Mail } from "@mui/icons-material";
import { MainButton, TextInput } from "../../components";
import { EmailFormatter, FORGOT_PASSWORD_ID, RESET_PASSWORD_ID, SIGNIN_ID, View, executeRecaptcha } from "../../shared";
import { ApiContext, NavigationContext, NotificationsContext, ThemeContext } from "../../contexts";
import "./forgotpasswordview.scss";

class ForgotPasswordView extends View {
    id = FORGOT_PASSWORD_ID;
    route = "/auth/forgot-password";
    defaultRoute = false;
    authNeeded = false;
    ref: string | null = null;
    header = {
        backClick: () => { this.navigation!.goTo(this.navigation!.views[SIGNIN_ID], undefined, !!this.ref ?  { ref: this.ref! } : undefined); },
        supportClick: () => {}
    };
    render = () => {
        const [email, setEmail] = React.useState<string>("");
        const [emailCursor, setEmailCursor] = React.useState<number>(0);
        const emailRef = React.useRef<HTMLInputElement>(null);
        const emailFormatter = new EmailFormatter();

        const { pushNotification } = React.useContext(NotificationsContext);
        const api = React.useContext(ApiContext);
        const { views, goTo } = this.navigation = React.useContext(NavigationContext);
        const { theme } = React.useContext(ThemeContext);

        const [searchParams] = useSearchParams();

        const init = () => {
            this.ref = searchParams.get("ref");
        }

        React.useEffect(init, []);

        const changeEmailHandle = (e: React.FormEvent<HTMLInputElement>) => {
            const updatedValue = emailFormatter.updateValue({text: email, cursor: emailCursor}, {text: e.currentTarget.value, cursor: e.currentTarget.selectionEnd || 0})
            setEmail(updatedValue.text);
            setEmailCursor(updatedValue.cursor);
            e.preventDefault();
        }

        React.useEffect(() => {
            const input = emailRef.current;
            if(input != null) {
                input.setSelectionRange(emailCursor, emailCursor);
            }
        }, [emailRef, emailCursor, email]);

        const forgotPassword = async (e: React.MouseEvent<HTMLButtonElement>) => {
            await executeRecaptcha(async (token: any) => {
                const failureForgotPassword = await api.auth.forgotPassword(token, email.trim());
                failureForgotPassword.match({
                    success: success => {
                        if(success) {
                            sessionStorage.setItem("register", JSON.stringify({
                                reason: "resetPassword",
                                email: email.trim(),
                            }));
                            goTo(views[RESET_PASSWORD_ID], undefined, !!searchParams.get("ref") ?  { ref: searchParams.get("ref")! } : undefined);
                        }
                    },
                    failure: () => {
                        pushNotification("Erro ao tentar iniciar o processo de reset de senha.");
                    }
                });
            });
            e.preventDefault();
        }

        return <div id="forgot-password">
            <div className="forgot-password-prompt">
                <img src={`/images/${theme}/forgot-password.svg`} />
                <h3>Esqueceu sua senha?</h3>
                <p>Não tem problema, me informa seu e-mail que vou mandar um código para que você possa resetá-la:</p>
            </div>
            <TextInput ref={emailRef} id="forgot-password-email" name="email" value={email} prefix={<Mail sx={{fontSize: 16.67, opacity: 0.6}} />} placeholder="Seu e-mail" onChange={changeEmailHandle} />
            <MainButton enabled={email.trim().match(/^[a-z0-9!#\$%&'*+/=?^_‘{|}~-]+(?:\.[a-z0-9!#\$%&'*+/=?^_‘{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/) != null} content="Resetar senha" onClick={forgotPassword} />
        </div>;
    }
}

export { ForgotPasswordView };